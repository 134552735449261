// ** Type import
import { HorizontalNavItemsType } from "src/page-layouts/types";

//! We are using the vertical navigation - go add pages there
const navigation = (): HorizontalNavItemsType => [
  // {
  //   title: 'Home',
  //   path: '/home',
  //   icon: 'mdi:home-outline',
  // },
  // {
  //   title: 'Second Page',
  //   path: '/second-page',
  //   icon: 'mdi:email-outline',
  // },
  // {
  //   path: '/acl',
  //   action: 'read',
  //   subject: 'acl-page',
  //   title: 'Access Control',
  //   icon: 'mdi:shield-outline',
  // }
];

export default navigation;
