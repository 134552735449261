/**
 *  Set Home URL based on User Roles
 */
const getHomeRoute = (role: string) => {
  //! customize home pages based on role
  return "/home";
  // if (role === 'user') return '/acl'
  // else return '/home'
};

export default getHomeRoute;
