import { defineAbility } from "@casl/ability";
import { User } from "@prisma/client";

export const defaultACLObj: ACLObj = {
  action: "manage",
  subject: "all",
};
export type Actions = "manage" | "create" | "read" | "update" | "delete";

export const roles = [
  { id: "admin", label: "Admin" },
  { id: "user", label: "User" },
] as const;

export type Role = (typeof roles)[number]["id"];

export const unauthenticatedRoutes: string[] = ["/auth/login", "reset-password", "forgot-password", "not-found", "/auth/error", "401", "404", "500"];

export const routeIsUnauthenticatedRoute = (route: string) => {
  const isUnauthenticatedRoute = unauthenticatedRoutes.reduce((isUnauthenticated: boolean, unauthenticatedRoute: string) => {
    if (route.includes(unauthenticatedRoute)) {
      isUnauthenticated = true;
    }

    return isUnauthenticated;
  }, false);

  return isUnauthenticatedRoute;
};

type Permission = string;
type RolePermissions = {
  user: Actions[];
};

interface Permissions {
  [key: Permission]: RolePermissions;
}

// cannot add a type to permissions because the PermissionsSubject type won't create a union type of the keys - please follow Permissions type when adding/changing
const permissions = {
  "home-page": {
    user: ["read"],
  },
  "administrative-settings": {
    user: [],
  },
  "user-management": {
    user: [],
  },
  "global-logs": {
    user: [],
  },
  "customers": {
    user: ["read"]
  },
  "location": {
    user: ["read"]
  },
  "contact": {
    user: ["read", "create", "delete"]
  },
  "credentials": {
    user: []
  }
};

export type PermissionSubject = keyof typeof permissions | "all";

export type ACLObj = {
  action: Actions;
  subject: PermissionSubject;
};

function hasKey<T extends object>(obj: T, key: any): key is keyof T {
  return key in obj;
}

export default (user: User) =>
  defineAbility((can, cannot) => {
    const isAdmin = user.role === "admin";
    const isUser = user.role === "user";

    if (isAdmin) {
      // grant ALL permissions to admins - "manage", "all" is a feature of casl
      can("manage", "all");
    } else if (isUser) {
      // loop through permissions and set ability
      for (const permission in permissions) {
        const subject = permission;

        // permission will always exist on permissions but we use hasKey to fix ts error where string can't be used to index permissions
        const permissionExistsOnPermissions = hasKey(permissions, permission);
        if (!permissionExistsOnPermissions) continue;

        // const actions: string[] | undefined = hasKey(permissions[permission], 'user') ? permissions[permission].user : undefined;
        const actions: string[] | undefined = permissions[permission].user;

        if (!actions) continue;

        can(actions, subject);
      }
    }
  });
