import * as LDClient from "launchdarkly-js-client-sdk";
import { ReactNode, createContext, useEffect, useState } from "react";

interface LaunchDarklyProviderProps {
  children: ReactNode;
  user: any;
}

export interface LaunchDarklyState {
  flags: LDClient.LDFlagSet;
  client: LDClient.LDClient | null;
	flagsInitialized: boolean,
}

const launchDarklyDefaultState: LaunchDarklyState = {
  flags: {},
  client: null,
  flagsInitialized: false
}

// ** Create Context
export const LaunchDarklyContext = createContext<LaunchDarklyState>(launchDarklyDefaultState);

export const LaunchDarklyStore = ({ children, user }: LaunchDarklyProviderProps) => {
  // ** State
  const [client, setClient] = useState<LDClient.LDClient | null>(null);
  const [flags, setFlags] = useState<LDClient.LDFlagSet>({});
	const [flagsInitialized, setFlagsInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (!user?.id) return;

    const context: LDClient.LDContext = {
      kind: "user",
      key: user.email,
    };

    const clientSideId = process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID;

    if (!clientSideId) {
      console.error("No Launch Darkly Client ID in env");
      return;
    }

    const ldClient = LDClient.initialize(clientSideId, context);

    ldClient
      .waitForInitialization()
      .then(() => {
        setClient(ldClient);

        const allFlags = ldClient.allFlags();

        setFlags(allFlags);
				setFlagsInitialized(true);

        ldClient.on("change", (settings) => {
          const updatedFlags = ldClient.allFlags();

          setFlags(updatedFlags);
        });
      })
      .catch((error) => {
        console.error("Failed to initialize Launch Darkly flags", error);
      });

    return () => {
      ldClient.close();
      setClient(null);
    };
  }, [user]);

  const values = {
    flags,
    client,
		flagsInitialized,
  };

  return <LaunchDarklyContext.Provider value={values}>{children}</LaunchDarklyContext.Provider>;
};

export const LaunchDarklyConsumer = LaunchDarklyContext.Consumer;