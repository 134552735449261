// ** Next imports
import { useRouter } from "next/router";

// ** React Imports
import { createContext, useState, ReactNode, useEffect } from "react";

// ** Types
import { FilterValuesType, Filter } from "./types";

// ** Defaults
const defaultProvider: FilterValuesType = {
  filters: [],
  setFilters: () => null,
  filtersOpen: false,
  setFiltersOpen: () => null,
  filterField: "",
  setFilterField: () => null,
  filterAnchorEl: null,
  setFilterAnchorEl: () => null,

  refreshFilters: () => null,
};

const FilterContext = createContext(defaultProvider);

type Props = {
  children: ReactNode;
};

const FilterProvider = ({ children }: Props) => {
  // ** States
  const [filters, setFilters] = useState<Filter[]>(defaultProvider.filters);
  const [filtersOpen, setFiltersOpen] = useState<boolean>(defaultProvider.filtersOpen);
  const [filterField, setFilterField] = useState<string>(defaultProvider.filterField);
  const [filterAnchorEl, setFilterAnchorEl] = useState<HTMLElement | null>(defaultProvider.filterAnchorEl);

  const router = useRouter();

  const refreshFilters = () => {
    setFilters(defaultProvider.filters);
    setFiltersOpen(defaultProvider.filtersOpen);
    setFilterField(defaultProvider.filterField);
    setFilterAnchorEl(defaultProvider.filterAnchorEl);
  };

  const values = {
    filters,
    setFilters,
    filtersOpen,
    setFiltersOpen,
    filterField,
    setFilterField,
    filterAnchorEl,
    setFilterAnchorEl,

    refreshFilters,
  };

  useEffect(() => {
    //! Every time pages change - reset filters so previous table filters don't apply to the wrong table
    return () => refreshFilters();
  }, [router.route]);

  useEffect(() => {
    console.log("filters", filters);
  }, [filters]);

  return <FilterContext.Provider value={values}>{children}</FilterContext.Provider>;
};

export { FilterContext, FilterProvider };
