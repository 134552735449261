// ** MUI Imports
import { Theme } from "@mui/material/styles";

// ** Hook Import
import { useSettings } from "src/hooks/useSettings";

// ** Util Import
import { hexToRGBA } from "src/utilities/client/template-helpers/hex-to-rgba";

const GlobalStyles = (theme: Theme) => {
  // ** Hook & Var
  const { settings } = useSettings();
  const { mode } = settings;

  const perfectScrollbarThumbBgColor = () => {
    if (mode === "light") {
      return "#C2C4D1 !important";
    } else {
      return "#504B6D !important";
    }
  };

  return {
    'body[style^="padding-right"] .layout-navbar-and-nav-container::after': {
      content: '""',
      position: "absolute" as const,
      left: "100%",
      top: 0,
      height: "100%",
      backgroundColor: hexToRGBA(theme.palette.background.paper, 0.85),
      width: "30px",
    },
    ".demo-space-x > *": {
      marginTop: "1rem !important",
      marginRight: "1rem !important",
      'body[dir="rtl"] &': {
        marginRight: "0 !important",
        marginLeft: "1rem !important",
      },
    },
    ".demo-space-y > *:not(:last-of-type)": {
      marginBottom: "1rem",
    },
    ".MuiGrid-container.match-height .MuiCard-root": {
      height: "100%",
    },
    ".ps__rail-y": {
      zIndex: 1,
      right: "0 !important",
      left: "auto !important",
      "&:hover, &:focus, &.ps--clicking": {
        backgroundColor: theme.palette.mode === "light" ? "#E4E5EB !important" : "#423D5D !important",
      },
      "& .ps__thumb-y": {
        right: "3px !important",
        left: "auto !important",
        backgroundColor: theme.palette.mode === "light" ? "#C2C4D1 !important" : "#504B6D !important",
      },
      ".layout-vertical-nav &": {
        "& .ps__thumb-y": {
          width: 4,
          backgroundColor: perfectScrollbarThumbBgColor(),
        },
        "&:hover, &:focus, &.ps--clicking": {
          backgroundColor: "transparent !important",
          "& .ps__thumb-y": {
            width: 6,
          },
        },
      },
    },

    "#nprogress": {
      pointerEvents: "none",
      "& .bar": {
        left: 0,
        top: 0,
        height: 3,
        width: "100%",
        zIndex: 2000,
        position: "fixed",
        backgroundColor: theme.palette.primary.main,
      },
    },
    // hyperlink text color
    "a.editor-paragraph": {
      color: theme.palette.mode === "dark" ? "lightblue !important" : "blue !important",
    },
    "a.editor-link.ltr": {
      color: theme.palette.mode === "dark" ? "lightblue !important" : "blue !important",
    },
  };
};

export default GlobalStyles;
