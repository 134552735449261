import { OwnerStateThemeType } from ".";

export default {
  MuiPaper: {
    styleOverrides: {
      root: ({ theme }: OwnerStateThemeType) => ({
        backgroundColor: theme.palette.customColors.tableHeaderBg,
        backgroundImage: 'none'
      }),
    }
  }
}
