// ** Next Imports
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import Head from "next/head";
import { Router } from "next/router";
import { useEffect, useState } from "react";

// ** Loader Import
import NProgress from "nprogress";

// ** Emotion Imports
import type { EmotionCache } from "@emotion/cache";
import { CacheProvider } from "@emotion/react";

// ** Config Imports

import { defaultACLObj } from "src/configs/permissions";
import themeConfig from "src/configs/themeConfig";

// ** Third Party Import
import Intercom, { trackEvent } from "@intercom/messenger-js-sdk";
import { SessionProvider } from "next-auth/react";
import { LaunchDarklyConsumer, LaunchDarklyStore } from "../context/LaunchDarklyFlagContext";

// ** Component Imports
import PermissionGuard from "src/components/auth/PermissionGuard";
import Store from "src/components/Store";
import UserLayout from "src/layouts/UserLayout";
import ThemeComponent from "src/theme/ThemeComponent";

// ** Contexts
import { AuthProvider } from "src/context/AuthContext";
import { FilterProvider } from "src/context/FiltersContext";
import { SettingsConsumer, SettingsProvider } from "src/context/settingsContext";
import { ToastConsumer, ToastProvider } from "src/context/ToastContext";

// ** Styled Components
import Toast from "src/components/toast";

// ** Utils Imports
import { createEmotionCache } from "src/utilities/client/template-helpers/create-emotion-cache";

// ** Types Imports
import { ACLObj } from "src/configs/permissions";
import { PageSpecificSettings } from "src/context/settingsContext";

// ** Prismjs Styles
import "prismjs";
import "prismjs/components/prism-jsx";
import "prismjs/components/prism-tsx";
import "prismjs/themes/prism-tomorrow.css";

// ** React Perfect Scrollbar Style
import "react-perfect-scrollbar/dist/css/styles.css";

import "src/iconify-bundle/icons-bundle-react";

// ** Global css styles

import { getReplay } from "@sentry/nextjs";

import "../../styles/globals.css";
import "../../styles/richTextEditor.css";

export type PageAuth = {
  auth: boolean;
  acl?: ACLObj;
};

type CustomComponentTypes = {
  auth: PageAuth;
  contentHeightFixed?: boolean;
  getLayout?: (page: any) => JSX.Element;
  setConfig?: () => PageSpecificSettings;
};

export type NextPageWithAuth<P = {}, IP = P> = NextPage<P, IP> & CustomComponentTypes;

type ComponentType = AppProps["Component"];
type CustomComponentType = ComponentType & CustomComponentTypes;

// ** Extend App Props with Emotion
type ExtendedAppProps = AppProps & {
  Component: CustomComponentType;
  emotionCache: EmotionCache;
};

const clientSideEmotionCache = createEmotionCache();

// ** Pace Loader
if (themeConfig.routingLoader) {
  Router.events.on("routeChangeStart", () => {
    NProgress.start();
  });
  Router.events.on("routeChangeError", () => {
    NProgress.done();
  });
  Router.events.on("routeChangeComplete", () => {
    NProgress.done();
  });
}

const defaultUser = {
  id: "",
  email: "",
  first_name: "",
  last_name: "",
  role: "",
  active: false,
};

// ** Configure JSS & ClassName
const App = (props: ExtendedAppProps) => {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps: { session, ...pageProps },
  } = props;

  // Variables
  const contentHeightFixed = Component.contentHeightFixed ?? false;
  const getLayout = Component.getLayout ?? ((page) => <UserLayout contentHeightFixed={contentHeightFixed}>{page}</UserLayout>);

  const setConfig = Component.setConfig ?? undefined;
  const [user, setUser] = useState(defaultUser);

  // if auth is false - anyone can access so there is a guestGuard on the page
  const guestGuard = Component.auth?.auth === false;

  const aclAbilities = Component.auth?.acl ?? defaultACLObj;

  // initialize intercom messenger
  useEffect(() => {
    if (user.id && typeof window !== "undefined") {
      const isLocalhost = window?.window?.location?.hostname?.toLowerCase().includes("localhost");
      if (!isLocalhost) {
        Intercom({
          app_id: "p6vpdgtg",
          user_id: user.id,
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          company: {
            id: "jcData",
            name: "JC Data",
          },
        });

        const currentReplayId = getReplay()?.getReplayId();

        if (currentReplayId) {
          const sentrySessionURL = `https://ksense-tech.sentry.io/replays/${currentReplayId}/`;

          trackEvent("session-started", { sentrySessionURL });
        }
      }
    }
  }, [user]);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>{`${themeConfig.applicationName} - Powerful Technology For Business`}</title>
        <meta
          name="description"
          content={`${themeConfig.applicationName} – your go-to application for seamless IT networking solutions, designed to simplify connections, enhance performance, and empower your network management journey!`}
        />
        <meta
          name="keywords"
          content="Orange IT, IT Networking, Network Management, Seamless Connections, Enhanced Performance, Network Solutions, IT Application, Connectivity, Network Tools, IT Infrastructure"
        />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link rel="icon" sizes="16x16 32x32 64x64" href="/favicons/favicon.ico" />
        <link rel="icon" type="image/png" sizes="196x196" href="/favicons/favicon-192.png" />
        <link rel="icon" type="image/png" sizes="160x160" href="/favicons/favicon-160.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/favicons/favicon-96.png" />
        <link rel="icon" type="image/png" sizes="64x64" href="/favicons/favicon-64.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16.png" />
        <link rel="apple-touch-icon" href="/favicons/favicon-57.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/favicons/favicon-114.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/favicons/favicon-72.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/favicons/favicon-144.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/favicons/favicon-60.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/favicons/favicon-120.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/favicons/favicon-76.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/favicons/favicon-152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/favicons/favicon-180.png" />
        <meta name="msapplication-TileColor" content="#FFFFFF" />
        <meta name="msapplication-TileImage" content="/favicons/favicon-144.png" />
      </Head>

      <SessionProvider session={session}>
        <Store setStoreUser={setUser}>
          <AuthProvider>
            <LaunchDarklyStore user={user}>
              <LaunchDarklyConsumer>
                {({ flagsInitialized }) => {
                  return (
                    <FilterProvider>
                      <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
                        <SettingsConsumer>
                          {({ settings }) => {
                            return (
                              <ThemeComponent settings={settings}>
                                <ToastProvider>
                                  <ToastConsumer>
                                    {({ toast }) => {
                                      return (
                                        <>
                                          {(flagsInitialized || guestGuard) && (
                                            <>
                                              <Toast {...toast} />
                                              <PermissionGuard aclAbilities={aclAbilities} guestGuard={guestGuard}>
                                                {getLayout(<Component {...pageProps} />)}
                                              </PermissionGuard>
                                              {/* <ReactHotToast>
                                  <Toaster position={settings.toastPosition} toastOptions={{ className: "react-hot-toast" }} />
                                </ReactHotToast> */}
                                            </>
                                          )}
                                        </>
                                      );
                                    }}
                                  </ToastConsumer>
                                </ToastProvider>
                              </ThemeComponent>
                            );
                          }}
                        </SettingsConsumer>
                      </SettingsProvider>
                    </FilterProvider>
                  );
                }}
              </LaunchDarklyConsumer>
            </LaunchDarklyStore>
          </AuthProvider>
        </Store>
      </SessionProvider>
    </CacheProvider>
  );
};

export default App;
