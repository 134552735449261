import * as Sentry from "@sentry/nextjs";
import { useSession } from "next-auth/react";
import { createContext, useEffect, useState } from "react";
import { getUserByEmail } from "src/utilities/client/CRUD/user";

export type UserContextType = {
  user: any;
  setUser: (user: any) => void;
  retrieveUser: (email: string) => Promise<void>;
};

interface forms {
  [key: string]: boolean;
}

export type FormsContextType = {
  forms: forms;
  setForms: (forms: forms) => void;
};

export type TopbarHeaderType = {
  topbarHeader: string;
  setTopbarHeader: (header: string) => void;
};

export type CurrentCompanyContextType = {
  currentCompany: { companyName: string; id: string };
  setCurrentCompany: (currentCompany: { companyName: string; id: string }) => void;
};

export type CommentsType = {
  savedComments: Array<{ id: string; comment?: any; commentId?: string; reply?: any }>;
  setSavedComments: (comments: Array<{ id: string; comment?: any; commentId?: string; reply?: any }>) => void;
};

export const UserContext = createContext<UserContextType | null>(null);

type Props = {
  children: JSX.Element;
  setStoreUser: (user: any) => void;
};

export default function Store({ children, setStoreUser }: Props) {
  const [user, setUser] = useState<any>({});
  const { data: session } = useSession();

  const refresh = () => {
    setUser({});
  };

  const retrieveUser = async (email: string) => {
    const userObj = await getUserByEmail(email);
    setUser(userObj);

    Sentry.setUser({ id: user.id, email: user?.email });
  };

  useEffect(() => {
    if (!user) return;
    setStoreUser(user);
  }, [user]);

  useEffect(() => {
    if (session === null) {
      refresh();
      return;
    }

    if (session?.user?.email) {
      retrieveUser(session.user.email);
    }
  }, [session]);

  return <UserContext.Provider value={{ user, setUser, retrieveUser }}>{children}</UserContext.Provider>;
}
