// ** Type import
import { VerticalNavItemsType } from "src/page-layouts/types";

const navigation = (): VerticalNavItemsType => {
  return [
    {
      icon: "eos-icons:admin-outlined",
      title: "Administrative Settings",
      action: "read", // action and subject on menus isn't required - it is built to only show if you have one of the children` permissions - here as an example
      subject: "administrative-settings",
      children: [
        {
          title: "Global Logs",
          path: "/global-logs",
          subject: "global-logs",
          action: "read",
          icon: "material-symbols:restore-page",
        },
        {
          title: "User Management",
          path: "/user-management",
          subject: "user-management",
          action: "read",
          icon: "mdi:users",
        },
      ],
      badgeColor: "default",
    },
  ];
};

export default navigation;
