import crudBuilder from "./crudBuilder";

export const userCrud = crudBuilder("user");

export const forgotPasswordCrud = crudBuilder("user", "forgot-password");

export const resetPasswordCrud = crudBuilder("user", "reset-password");

export const customerCrud = crudBuilder("customer");

export const locationCrud = crudBuilder("location");

export const contactCrud = crudBuilder("contact");

export const documentCrud = crudBuilder("document");

export const credentialCrud = crudBuilder("credential");

export const assetCrud = crudBuilder("asset")

export const credentialHistoryCrud = crudBuilder("credential-history");

export const documentHistoryCrud = crudBuilder("document-history");
