import React from 'react'

interface Props {
  svgString: string
}

function RenderSVG({ svgString }: Props) {
  return <div dangerouslySetInnerHTML={{ __html: svgString }} />
}

export default RenderSVG
