// ** React Imports
import React, { useState, useEffect } from "react";

// ** MUI Imports
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export interface ToastParams {
  message: string;
  position?: {
    horizontal: "center" | "left" | "right";
    vertical: "bottom" | "top";
  };
  sx?: any;
  duration?: number;
  severity?: "error" | "warning" | "info" | "success";
  open: boolean;
  onClose?: () => void;
}

const Toast = (params: ToastParams) => {
  const { message = "", position = { horizontal: "left", vertical: "bottom" }, sx = {}, duration = 3000, severity, open, onClose } = params;

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(open);

  const handleClose = (event?: Event | React.SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    // Close the Snackbar and trigger the `onClose` callback (if provided)
    setSnackbarOpen(false);
    onClose && onClose();
  };

  useEffect(() => {
    // When the `open` prop changes, update the internal state to control the Snackbar's open state
    setSnackbarOpen(open);
  }, [open]);

  useEffect(() => {
    // Auto-close the Snackbar after the specified duration
    if (snackbarOpen) {
      const timer = setTimeout(() => {
        handleClose();
      }, duration);

      // Clear the timer when the component unmounts or when `snackbarOpen` changes
      return () => {
        clearTimeout(timer);
      };
    }
  }, [snackbarOpen, duration]);

  return severity ? (
    <Snackbar autoHideDuration={duration} anchorOrigin={position} sx={sx} open={snackbarOpen} onClose={handleClose}>
      <Alert severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  ) : (
    <Snackbar autoHideDuration={duration} message={message} anchorOrigin={position} sx={sx} open={snackbarOpen} onClose={handleClose} />
  );
};

export default Toast;
