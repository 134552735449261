// ** Type Imports
import { Palette } from "@mui/material";
import { Skin, ThemeColor } from "src/page-layouts/types";
import { colorTheme } from "../colorTheme";

//! Everything commented out is from the default template theme - uncomment to compare how their template looked if needed

const DefaultPalette = (mode: Palette["mode"], skin: Skin, themeColor: ThemeColor): Palette => {
  // ** Vars
  const whiteColor = "#FFF";
  const lightColor = "58, 53, 65";
  //   const darkColor = '231, 227, 252'
  const darkColor = "253, 245, 231";
  const mainColor = mode === "light" ? lightColor : darkColor;

  const primaryGradient = () => {
    // if (themeColor === 'primary') {
    //   return '#C6A7FE' // light purple
    // } else if (themeColor === 'secondary') {
    //   return '#9C9FA4' // gray
    // } else if (themeColor === 'success') {
    //   return '#93DD5C' // greenish
    // } else if (themeColor === 'error') {
    //   return '#FF8C90' // reddish
    // } else if (themeColor === 'warning') {
    //   return '#FFCF5C' // yellow-orange
    // } else {
    //   return '#6ACDFF' // cyan blue-ish
    // }
    if (themeColor === "primary") {
      return colorTheme.orange;
    } else if (themeColor === "secondary") {
      return colorTheme.gray;
    } else if (themeColor === "success") {
      return colorTheme.green;
    } else if (themeColor === "error") {
      return colorTheme.red;
    } else if (themeColor === "warning") {
      return colorTheme.yellow;
    } else {
      return colorTheme.lightOrange;
    }
  };

  //   const defaultBgColor = () => {
  //     if (skin === 'bordered' && mode === 'light') {
  //       return whiteColor
  //     } else if (skin === 'bordered' && mode === 'dark') {
  //       return '#312D4B'
  //     } else if (mode === 'light') {
  //       return '#F4F5FA'
  //     } else return '#28243D'
  //   }

  const defaultBgColor = () => {
    if (skin === "bordered" && mode === "light") {
      return whiteColor;
    } else if (skin === "bordered" && mode === "dark") {
      // return '#312D4B' // very dark blue - almost black
      return colorTheme.darkGrey;
    } else if (mode === "light") {
      // return '#F4F5FA' // very light blue - almost white
      return colorTheme.lightBeige;
      // } else return '#28243D' // very dark purple - almost black
    } else return colorTheme.darkGrey; // very dark purple - almost black
  };

  return {
    customColors: {
      dark: darkColor,
      main: mainColor,
      light: lightColor,
      primaryGradient: primaryGradient(),
      // bodyBg: mode === 'light' ? '#F4F5FA' : '#28243D', // Same as palette.background.default but doesn't consider bordered skin
      bodyBg: mode === "light" ? colorTheme.lightBeige : colorTheme.gray, // Same as palette.background.default but doesn't consider bordered skin
      // trackBg: mode === 'light' ? '#F0F2F8' : '#474360',
      trackBg: mode === "light" ? colorTheme.lightBeige : colorTheme.gray,
      // avatarBg: mode === 'light' ? '#F0EFF0' : '#3F3B59',
      avatarBg: mode === "light" ? colorTheme.lightBeige : colorTheme.gray,
      // darkBg: skin === 'bordered' ? '#312D4B' : '#28243D',
      darkBg: skin === "bordered" ? colorTheme.gray : colorTheme.darkGrey,
      // lightBg: skin === 'bordered' ? whiteColor : '#F4F5FA',
      lightBg: skin === "bordered" ? whiteColor : colorTheme.lightBeige,
      // tableHeaderBg: mode === "light" ? "#F9FAFC" : "#3D3759",
      tableHeaderBg: mode === "light" ? "white" : colorTheme.lightGrey,
      // button: {
      //   light: colorTheme.orange,
      //   main: colorTheme.darkOrange,
      //   dark: colorTheme.veryDarkOrange,
      //   contrastText: whiteColor,
      // },
    },
    mode: mode,
    common: {
      black: "#000",
      white: whiteColor,
    },
    primary: {
      // light: '#9E69FD',
      // main: '#9155FD',
      // dark: '#804BDF',
      light: colorTheme.lightOrange,
      main: colorTheme.orange,
      dark: colorTheme.darkOrange,
      contrastText: whiteColor,
    },
    secondary: {
      // light: '#9C9FA4',
      // main: '#8A8D93',
      // dark: '#777B82',
      light: colorTheme.gray,
      main: colorTheme.gray,
      dark: colorTheme.darkGray,
      contrastText: whiteColor,
    },
    error: {
      // light: '#FF6166',
      // main: '#FF4C51',
      // dark: '#E04347',
      light: colorTheme.lightRed,
      main: colorTheme.red,
      dark: colorTheme.darkRed,
      contrastText: whiteColor,
    },
    warning: {
      // light: '#FFCA64',
      // main: '#FFB400',
      // dark: '#E09E00',
      light: colorTheme.lightYellow,
      main: colorTheme.yellow,
      dark: colorTheme.darkYellow,
      contrastText: whiteColor,
    },
    info: {
      light: "#32BAFF",
      main: "#16B1FF",
      dark: "#139CE0",
      contrastText: whiteColor,
    },
    success: {
      // light: '#6AD01F',
      // main: '#56CA00',
      // dark: '#4CB200',
      light: colorTheme.lightGreen,
      main: colorTheme.green,
      dark: colorTheme.darkGreen,
      contrastText: whiteColor,
    },
    grey: {
      50: "#FAFAFA",
      100: "#F5F5F5",
      200: "#EEEEEE",
      300: "#E0E0E0",
      400: "#BDBDBD",
      500: "#9E9E9E",
      600: "#757575",
      700: "#616161",
      800: "#424242",
      900: "#212121",
      A100: "#F5F5F5",
      A200: "#EEEEEE",
      A400: "#BDBDBD",
      A700: "#616161",
    },
    text: {
      primary: `rgba(${mainColor}, 0.87)`,
      secondary: `rgba(${mainColor}, 0.6)`,
      disabled: `rgba(${mainColor}, 0.38)`,
    },
    divider: `rgba(${mainColor}, 0.12)`,
    background: {
      paper: mode === "light" ? whiteColor : colorTheme.darkGray,
      default: defaultBgColor(),
    },
    action: {
      active: `rgba(${mainColor}, 0.54)`,
      hover: `rgba(${mainColor}, 0.04)`,
      selected: `rgba(${mainColor}, 0.08)`,
      disabled: `rgba(${mainColor}, 0.26)`,
      disabledBackground: `rgba(${mainColor}, 0.12)`,
      focus: `rgba(${mainColor}, 0.12)`,
    },
  } as Palette;
};

export default DefaultPalette;
