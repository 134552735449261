const capitalizeFirstLetter = (str: string) => {
  if (!str) return ''

  let formatted = str
    .toLowerCase()
    .split(' ')
    .filter(Boolean)
    .map(word => `${word[0].toUpperCase()}${word.substring(1)}`)
    .join(' ')

  if (formatted.includes('-')) {
    formatted = formatted
      .split('-')
      .map((word: string) => `${word[0].toUpperCase()}${word.substring(1)}`)
      .join('-')
  }
  return formatted
}

export default capitalizeFirstLetter
