// ** Type Import
import { OwnerStateThemeType } from ".";
import { Skin } from "src/page-layouts/types";
import { colorTheme, overrides } from "../colorTheme";

const DataGrid = (skin: Skin) => {
  const boxShadow = (theme: OwnerStateThemeType["theme"]) => {
    if (skin === "bordered") {
      return theme.shadows[0];
    } else if (theme.palette.mode === "light") {
      return theme.shadows[8];
    } else return theme.shadows[9];
  };

  return {
    MuiDataGrid: {
      styleOverrides: {
        root: ({ theme }: OwnerStateThemeType) => ({
          backgroundColor: theme.palette.customColors.tableHeaderBg,
          boxShadow: boxShadow(theme),
          color: theme.palette.text.primary,
          borderColor: theme.palette.divider,
          "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-pinnedColumnHeaders": {
            backgroundColor: theme.palette.customColors.tableHeaderBg,
          },
          "& .MuiDataGrid-pinnedColumnHeaders, & .MuiDataGrid-pinnedColumns": {
            backgroundColor: theme.palette.customColors.tableHeaderBg,
            boxShadow: "none !important",
            backgroundImage: "none",
          },
        }),
        toolbarContainer: ({ theme }: OwnerStateThemeType) => ({
          paddingRight: `${theme.spacing(5)} !important`,
          paddingLeft: `${theme.spacing(3.25)} !important`,
        }),
        columnHeaders: ({ theme }: OwnerStateThemeType) => ({
          borderRadius: 0,
        }),
        columnHeader: ({ theme }: OwnerStateThemeType) => ({
          "&:not(.MuiDataGrid-columnHeaderCheckbox)": {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            "&:first-of-type": {
              paddingLeft: theme.spacing(5),
            },
          },
          "&:last-of-type": {
            paddingRight: theme.spacing(5),
          },
        }),
        columnHeaderCheckbox: {
          maxWidth: "58px !important",
          minWidth: "58px !important",
        },
        columnHeaderTitleContainer: {
          padding: 0,
        },
        columnHeaderTitle: {
          fontWeight: 600,
          fontSize: "0.75rem",
          letterSpacing: "0.17px",
          textTransform: "uppercase",
        },
        columnSeparator: ({ theme }: OwnerStateThemeType) => ({
          color: theme.palette.divider,
        }),
        row: ({ theme }: OwnerStateThemeType) => ({
          "&:last-child": {
            "& .MuiDataGrid-cell": {
              borderBottom: 0,
            },
          },
        }),
        cell: ({ theme }: OwnerStateThemeType) => ({
          borderColor: theme.palette.divider,
          "&:not(.MuiDataGrid-cellCheckbox)": {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            "&:first-of-type": {
              paddingLeft: theme.spacing(5),
            },
          },
          "&:last-of-type": {
            paddingRight: theme.spacing(5),
          },
          "&:focus, &:focus-within": {
            outline: "none",
          },
        }),
        cellCheckbox: {
          maxWidth: "58px !important",
          minWidth: "58px !important",
        },
        editInputCell: ({ theme }: OwnerStateThemeType) => ({
          padding: 0,
          color: theme.palette.text.primary,
          "& .MuiInputBase-input": {
            padding: 0,
          },
        }),
        footerContainer: ({ theme }: OwnerStateThemeType) => ({
          borderTop: `1px solid ${theme.palette.divider}`,
          "& .MuiTablePagination-toolbar": {
            paddingLeft: `${theme.spacing(4)} !important`,
            paddingRight: `${theme.spacing(4)} !important`,
          },
          "& .MuiTablePagination-displayedRows, & .MuiTablePagination-selectLabel": {
            color: theme.palette.text.primary,
          },
        }),
        selectedRowCount: ({ theme }: OwnerStateThemeType) => ({
          margin: 0,
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
        }),
      },
    },
  };
};

export default DataGrid;
