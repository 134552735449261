// ** React Imports
import { createContext, ReactNode, useEffect, useState } from "react";

// ** Next Import
import { useRouter } from "next/router";

// ** Next-Auth Import
import { signIn, signOut, useSession } from "next-auth/react";

// ** CRUD
import { userCrud } from "src/utilities/client/CRUD/cruds";

// ** Types
import { User } from "@prisma/client";
import { Ability, AuthValuesType, AvatarOptions, LoginParams } from "./types";

// ** Util Import
import defineAbilityFor, { routeIsUnauthenticatedRoute } from "src/configs/permissions";
import getHomeRoute from "src/layouts/components/acl/getHomeRoute";

// ** Avatars
import { bottts } from "@dicebear/collection";
import { createAvatar, Result } from "@dicebear/core";
import * as Sentry from "@sentry/nextjs";

// ** Defaults
const defaultProvider: AuthValuesType = {
  user: null,
  loading: false,
  setUser: () => null,
  setLoading: () => Boolean,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  ability: null,
  generateAvatar: () => null,
};

const AuthContext = createContext(defaultProvider);

type Props = {
  children: ReactNode;
};

const AuthProvider = ({ children }: Props) => {
  // ** States
  const [user, setUser] = useState<User | null>(defaultProvider.user);
  const [loading, setLoading] = useState<boolean>(defaultProvider.loading);
  const [ability, setAbility] = useState<Ability | null>(defaultProvider.ability);

  const { data: session, status }: { data: any; status: "loading" | "unauthenticated" | "authenticated" } = useSession();

  // ** Hooks
  const router = useRouter();

  const refresh = () => {
    setUser(defaultProvider.user);
    setLoading(defaultProvider.loading);
    setAbility(defaultProvider.ability);
  };

  /**
   * @description This will handle validating a user's session and routing based on that
   *    If unauthenticated session and we are on an auth page - it will take us to the sign in
   *    If authenticated - it will verify there is a user record that matches our session user
   *          The reason for this is when we reset the database like for staging - the session will still exist but the user no longer does
   */
  const validateUserAndSession = async (pageAuth?: boolean) => {
    if (session === undefined || status === "loading") return;

    if (status === "unauthenticated") {
      refresh();

      const routeIsUnauthenticated = routeIsUnauthenticatedRoute(router.route);

      if (!routeIsUnauthenticated) {
        signIn();

        return;
      }
    }

    if (status === "authenticated") {
      let userRecord = user;

      if (!pageAuth) {
        const getParams = {
          id: session.user?.id,
        };

        userRecord = await userCrud.get(getParams);

        if (!userRecord) {
          signOut();

          return;
        }

        setUser(userRecord);
        Sentry.setUser({ id: user?.id, email: user?.email });
      }

      // redirect from base route if we are authenticated
      if (router.pathname === "/") {
        const homeRoute = getHomeRoute(userRecord?.role || "");
        router.replace(homeRoute);
      }
    }
  };
  useEffect(() => {
    validateUserAndSession();
  }, [session, status]);

  useEffect(() => {
    validateUserAndSession(true);
  }, [router.route]);

  const handleLogin = async (params: LoginParams) => {
    const { username, password, rememberMe } = params;

    try {
      const response = await signIn("credentials", {
        username,
        password,
        rememberMe,
        callbackUrl: "/auth/login",
        redirect: false,
      });

      if (response?.status !== 200 || response?.error) {
        throw response?.error;
      }
    } catch (err) {
      throw err;
    }
  };

  const handleLogout = async () => {
    const currentUrl = location.href;
    const parsed = new URL(currentUrl);
    const origin = parsed.origin;

    await signOut({
      redirect: false,
      callbackUrl: `${origin}/auth/logout`,
    });

    setLoading(true);
  };

  const generateAvatar = (options: AvatarOptions) => {
    if (!user) return null;

    const userAvatar: Result = createAvatar(bottts, {
      seed: user.id,
      ...options,
    });

    return userAvatar;
  };

  useEffect(() => {
    if (!user) return;

    // set ability for casl library
    const userAbility = defineAbilityFor(user);
    setAbility(userAbility);
  }, [user]);

  const values = {
    user,
    loading,
    setUser,
    setLoading,
    login: handleLogin,
    logout: handleLogout,
    ability,
    generateAvatar,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
