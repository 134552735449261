// ** Next Import
import Link from "next/link";

// ** MUI Imports
import IconButton from "@mui/material/IconButton";
import Box, { BoxProps } from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

// ** Type Import
import { LayoutProps } from "src/page-layouts/types";

// ** Custom Icon Import
import Icon from "src/components/icon";

// ** Configs
import themeConfig from "src/configs/themeConfig";

interface Props {
  navHover: boolean;
  collapsedNavWidth: number;
  hidden: LayoutProps["hidden"];
  navigationBorderWidth: number;
  toggleNavVisibility: () => void;
  settings: LayoutProps["settings"];
  saveSettings: LayoutProps["saveSettings"];
  navMenuBranding?: LayoutProps["verticalLayoutProps"]["navMenu"]["branding"];
  menuLockedIcon?: LayoutProps["verticalLayoutProps"]["navMenu"]["lockedIcon"];
  menuUnlockedIcon?: LayoutProps["verticalLayoutProps"]["navMenu"]["unlockedIcon"];
}

// ** Styled Components
const MenuHeaderWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingRight: theme.spacing(4.5),
  transition: "padding .25s ease-in-out",
  minHeight: theme.mixins.toolbar.minHeight,
}));

const HeaderTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 600,
  lineHeight: "normal",
  textTransform: "uppercase",
  color: theme.palette.text.primary,
  transition: "opacity .25s ease-in-out, margin .25s ease-in-out",
}));

const LinkStyled = styled(Link)({
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
});

const VerticalNavHeader = (props: Props) => {
  // ** Props
  const {
    hidden,
    navHover,
    settings,
    saveSettings,
    collapsedNavWidth,
    toggleNavVisibility,
    navigationBorderWidth,
    menuLockedIcon: userMenuLockedIcon,
    navMenuBranding: userNavMenuBranding,
    menuUnlockedIcon: userMenuUnlockedIcon,
  } = props;

  // ** Hooks & Vars
  const theme = useTheme();
  const { navCollapsed } = settings;

  const menuCollapsedStyles = navCollapsed && !navHover ? { opacity: 0 } : { opacity: 1 };

  const menuHeaderPaddingLeft = () => {
    if (navCollapsed && !navHover) {
      if (userNavMenuBranding) {
        return 0;
      } else {
        return (collapsedNavWidth - navigationBorderWidth - 30) / 8;
      }
    } else {
      return 6;
    }
  };
  const MenuLockedIcon = () => userMenuLockedIcon || <Icon icon="uiw:d-arrow-left" />;

  const MenuUnlockedIcon = () => userMenuUnlockedIcon || <Icon icon="uiw:d-arrow-right" />;


  return (
    <MenuHeaderWrapper className="nav-header" sx={{ pl: menuHeaderPaddingLeft() }}>
      {userNavMenuBranding ? (
        userNavMenuBranding(props)
      ) : (
        <LinkStyled href="/">
          <img alt="Orange IT Small Logo" src="/images/Orange favicon.png" width="30" />
          <HeaderTitle variant="h6" sx={{ ...menuCollapsedStyles, ...(navCollapsed && !navHover ? {} : { ml: 3 }) }}>
            {themeConfig.applicationName}
          </HeaderTitle>
        </LinkStyled>
      )}

      {hidden ? (
        <IconButton disableRipple disableFocusRipple onClick={toggleNavVisibility} sx={{ p: 0, backgroundColor: "transparent !important" }}>
          <Icon icon="mdi:close" fontSize={20} />
        </IconButton>
      ) : userMenuLockedIcon === null && userMenuUnlockedIcon === null ? null : (
        <Tooltip title={navCollapsed ? "Expand" : "Collapse"} placement="right">
        <IconButton
          disableRipple
          disableFocusRipple
          onClick={() => saveSettings({ ...settings, navCollapsed: !navCollapsed })}
          sx={{
            p: 0,
            color: "text.primary",
            backgroundColor: "transparent !important",
            "& svg": {
              fontSize: "1.25rem",
              ...menuCollapsedStyles,
              transition: "opacity .25s ease-in-out",
            },
          }}
        >
          {navCollapsed ? MenuUnlockedIcon() : MenuLockedIcon()}
        </IconButton>
        </Tooltip>
      )}
    </MenuHeaderWrapper>
  );
};

export default VerticalNavHeader;
