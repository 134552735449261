// ** Type import
import { VerticalNavItemsType } from "src/page-layouts/types";

const navigation = (): VerticalNavItemsType => {
  return [
    {
      title: "Home",
      path: "/home",
      subject: "home-page",
      action: "read",
      icon: "mdi:home-outline",
    },
    {
      title: "Customers",
      path: "/customers",
      subject: "customers",
      action: "read",
      icon: "ion:people-outline",
    },
  ];
};

export default navigation;
