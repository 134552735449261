import axios from "axios";

export const getUserByEmail = async (email: string) => {
  try {
    const response = await axios.get(`/api/user/email/${email.toLowerCase().trim()}`);
    return response.data.user;
  } catch (err) {
    console.error("Unable to retrieve user", err);
  }
};
