// ** React Imports
import { ReactNode } from "react";

// ** Next Import
import { useRouter } from "next/router";

// ** Types
import type { ACLObj } from "src/configs/permissions";
import type { Ability } from "src/context/types";

// ** Context Imports
import { AbilityContext } from "src/layouts/components/acl/Can";

// ** Config Import
import { routeIsUnauthenticatedRoute } from "src/configs/permissions";

// ** Component Import
import NotAuthorized from "src/pages/401";
import Spinner from "src/components/spinner";
import BlankLayout from "src/page-layouts/BlankLayout";

// ** Hooks
import { useAuth } from "src/hooks/useAuth";

interface AclGuardProps {
  children: ReactNode;
  guestGuard?: boolean;
  aclAbilities: ACLObj;
}

const PermissionGuard = (props: AclGuardProps) => {
  // ** Props
  const { aclAbilities, children, guestGuard = false } = props;

  // ** Hooks
  const auth = useAuth();
  const router = useRouter();

  const ability: Ability = auth.ability;

  const isUnauthenticatedRoute = routeIsUnauthenticatedRoute(router.route);

  // User is logged in, build ability for the user based on his role
  if ((auth.user && !ability) || (!auth.user && !ability && !isUnauthenticatedRoute)) {
    return <Spinner />;
  }

  // If guest guard or no guard is true or any error page
  if (guestGuard || router.route === "/404" || router.route === "/401" || router.route === "/500") {
    // If user is logged in and his ability is built
    if (auth.user && ability) {
      return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
    } else {
      // If user is not logged in (render pages like login, register etc..)
      return <>{children}</>;
    }
  }

  // Check the access of current user and render pages
  if (ability && auth.user && ability.can(aclAbilities.action, aclAbilities.subject)) {
    if (router.route === "/") {
      return <Spinner />;
    }

    return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
  }

  // Render Not Authorized component if the current user has limited access
  return (
    <BlankLayout>
      <NotAuthorized />
    </BlankLayout>
  );
};

export default PermissionGuard;
