// ** React Imports
import { createContext, useState, ReactNode, useEffect } from "react";

import { ToastParams } from "src/components/toast";

export type ToastContextValue = {
  showToast: (toast: ShowToastProps) => void;
  toast: ToastParams;
};

interface ToastProviderProps {
  children: ReactNode;
}

export interface ShowToastProps {
  message: string;
  position?: {
    horizontal: "center" | "left" | "right";
    vertical: "bottom" | "top";
  };
  sx?: any;
  duration?: number;
  severity?: "error" | "warning" | "info" | "success";
  onClose?: () => void;
}

const initialValues: ToastParams = {
  message: "",
  position: {
    horizontal: "left",
    vertical: "bottom",
  },
  sx: {},
  duration: 3000,
  severity: undefined,
  open: false,
  onClose: () => null,
};

// ** Create Context
export const ToastContext = createContext<ToastContextValue>({
  showToast: (initalValues: ShowToastProps) => null,
  toast: initialValues,
});

export const ToastProvider = ({ children }: ToastProviderProps) => {
  // ** State
  const [toast, setToast] = useState<ToastParams>(initialValues);

  const showToast = (toastParams: ShowToastProps) => {
    setToast({
      ...toast,
      ...toastParams,
      open: true,
    });
  };

  useEffect(() => {
    if (!toast.open) return;

    const closeTimeout = setTimeout(() => {
      setToast(initialValues);
    }, toast.duration);

    return () => {
      clearTimeout(closeTimeout);
    };
  }, [toast]);

  return <ToastContext.Provider value={{ toast, showToast }}>{children}</ToastContext.Provider>;
};

export const ToastConsumer = ToastContext.Consumer;
