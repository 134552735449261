// ** React Imports
import { ReactNode } from "react";

// ** Next Import
import Link from "next/link";

// ** MUI Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box, { BoxProps } from "@mui/material/Box";

// ** Layout Import
import BlankLayout from "src/page-layouts/BlankLayout";

// ** Type Imports
import { NextPageWithAuth } from "src/pages/_app";

// ** Styled Components
const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "90vw",
  },
}));

const Img = styled("img")(({ theme }) => ({
  marginBottom: theme.spacing(10),
  [theme.breakpoints.down("lg")]: {
    height: 450,
    marginTop: theme.spacing(10),
  },
  [theme.breakpoints.down("md")]: {
    height: 400,
  },
  [theme.breakpoints.up("lg")]: {
    marginTop: theme.spacing(13),
  },
}));

const Error401: NextPageWithAuth = () => {
  return (
    <Box className="content-center">
      <Box sx={{ p: 5, display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
        <BoxWrapper>
          <Typography variant="h1">401</Typography>
          <Typography variant="h5" sx={{ mb: 1, fontSize: "1.5rem !important" }}>
            You are not authorized! 🔐
          </Typography>
          <Typography variant="body2">You don&prime;t have permission to access this page. Go Home!</Typography>
        </BoxWrapper>
        <Img height="487" alt="error-illustration" src="/images/pages/401.png" />
        <Button href="/" component={Link} variant="contained" sx={{ px: 5.5 }}>
          Back to Home
        </Button>
      </Box>
    </Box>
  );
};

Error401.getLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>;

Error401.auth = {
  auth: false,
};

export default Error401;
